import request from '@/utils/request';

export const login = (data) =>
request({
	url: `/login/check`,
	method: 'post',
	data
})

export const getAdminInfo = (id) =>
request({
	url: '/system_admin/detail',
	method: 'get',
	params: {
        id: id
    }
})

export const getAdminList = (params) =>
request({
	url: '/system_admin/lists',
	method: 'get',
	params
})

export const changeStatus = (data) =>
request({
	url: `/system_admin/updateAdminStatus`,
	method: 'post',
	data
})

export const createAdmin = (data) =>
request({
	url: `/system_admin/add`,
	method: 'post',
	data
})

export const deleteAdmin = (id) =>
request({
	url: `/system_admin/delete`,
	method: 'post',
	data: {
		id: id
	}
})