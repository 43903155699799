import { createRouter, createWebHashHistory } from 'vue-router'
import LayoutView from '@/layout/LayoutView.vue'

const routes = [
    {
        path: '/',
        component: LayoutView,
        redirect: '/console',
        children: [
            {
                path: 'console',
                name: 'ConsoleView',
                meta:{ title: "首页" },
                component: () => import('@/views/console/ConsoleView.vue')
            }
        ]
    },
    {
        path: '/login',
        meta: { title: '登录' },
        component: () => import('@/views/login/LoginView.vue')
    },
    {
        path: '/admin',
        component: LayoutView,
        children: [
            {
                path: 'index',
                name: 'AdminList',
                meta: { title: '权限管理' },
                component: () => import('@/views/admin/AdminList.vue')
            }
        ]
    },
    {
        path: '/client',
        component: LayoutView,
        redirect: '/client/index',
        children: [
            {
                path: 'index',
                name: 'ClientList',
                meta: {
                    title: '客户管理',
                },
                component: () => import('@/views/client/ClientList.vue')
            }
        ],
    },
    {
        path: '/product',
        component: LayoutView,
        redirect: '/product/index',
        children: [
            {
                path: 'index',
                name: 'ProductList',
                meta: { title: '产品管理' },
                component: () => import('@/views/product/ProductList.vue')
            },
            {
                path: 'edit',
                name: 'ProductEdit',
                meta: { title: '产品编辑' },
                component: () => import('@/views/product/ProductEdit.vue')
            }
        ]
    },
    {
        path: '/clue',
        component: LayoutView,
        children: [
            {
                path: 'myClue',
                name: 'MyClue',
                meta: { title: '我的线索' },
                component: () => import('@/views/clue/MyClue.vue')
            },
            {
                path: 'connectList',
                name: 'ConnectList',
                meta: { title: '离职交接' },
                component: () => import('@/views/clue/ConnectList.vue')
            },
            {
                path: 'reserveList',
                name: 'ReserveList',
                meta: { title: '预约考察' },
                component: () => import('@/views/clue/ReserveList.vue')
            },
            {
                path: 'unassignedClue',
                name: 'UnassignedClue',
                meta: { title: '分配线索' },
                component: () => import('@/views/clue/UnassignedClue.vue')
            },
            {
                path: 'bathAdd',
                name: 'BathAdd',
                meta: { title: '批量添加' },
                component: () => import('@/views/clue/BathAdd.vue')
            },
            {
                path: 'followUpClue',
                name: 'FollowUpClue',
                meta: { title: '跟进线索' },
                component: () => import('@/views/clue/FollowUpClue.vue')
            },
            {
                path: 'publicClue',
                name: 'PublicClue',
                meta: { title: '线索公海' },
                component: () => import('@/views/clue/PublicClue.vue')
            },
        ]
    },
    {
        path: '/staff',
        name: 'Staff',
        component: LayoutView,
        redirect: '/staff/index',
        children: [
            {
                path: 'index',
                name: 'StaffList',
                meta: { title: '职员管理' },
                component: () => import('@/views/staff/StaffList.vue')
            }
        ],
    },
    {
        path: '/department',
        name: 'Department',
        component: LayoutView,
        redirect: '/department/index',
        children: [
            {
                path: 'index',
                name: 'DepartmentList',
                meta: { title: '部门管理' },
                component: () => import('@/views/department/DepartmentList.vue')
            }
        ],
    },
    {
        path: '/design',
        component: LayoutView,
        children: [
            {
                path: 'index',
                name: 'DesignView',
                meta: { title: '方案设计' },
                component: () => import('@/views/design/DesignView.vue')
            }
        ]
    },
    {
        path: '/design/draw',
        name: 'DrawView',
        component: () => import('@/views/design/DrawView.vue')
    },
    {
        path: '/scheme',
        component: LayoutView,
        children: [
            {
                path: 'designDetail',
                name: 'DesignDetail',
                meta: { title: '方案详情' },
                component: () => import('@/views/scheme/DesignDetail.vue')
            },
            {
                path: 'quoteDetail',
                name: 'QuoteDetail',
                meta: { title: '报价详情' },
                component: () => import('@/views/scheme/QuoteDetail.vue')
            }
        ],
    },
    {
        path: '/myQuote',
        component: LayoutView,
        children: [
            {
                path: 'index',
                name: 'MyQuote',
                meta: { title: '我的方案' },
                component: () => import('@/views/my-quote/MyQuote.vue')
            },
        ]
    },
    {
        path: '/quickoffer',
        component: LayoutView,
        children: [
            {
                path: 'index',
                name: 'QuickofferView',
                meta: { title: '报价列表' },
                component: () => import('@/views/quickoffer/QuickofferView.vue')
            },
            {
                path: 'edit',
                name: 'EditQuickoffer',
                meta: { title: '快速报价' },
                component: () => import('@/views/quickoffer/EditQuickoffer.vue')
            }
        ]
    },
    {
        path: '/salesOrder',
        component: LayoutView,
        children: [
            {
                path: 'index',
                name: 'SalesOrderList',
                meta: { title: '销售订单' },
                component: () => import('@/views/sales-order/SalesOrderList.vue')
            },
            {
                path: 'detail',
                name: 'SalesOrderDetail',
                meta: { title: '订单详情' },
                component: () => import('@/views/sales-order/SalesOrderDetail.vue')
            },
            {
                path: 'add',
                name: 'AddSalesOrder',
                meta: { title: '添加订单' },
                component: () => import('@/views/sales-order/AddSalesOrder.vue')
            },
            {
                path: 'edit',
                name: 'EditSalesOrder',
                meta: { title: '编辑订单' },
                component: () => import('@/views/sales-order/EditSalesOrder.vue')
            }
        ]
    },
    {
        path: '/returnOrder',
        component: LayoutView,
        children: [
            {
                path: 'index',
                name: 'ReturnOrderList',
                meta: { title: '退换订单' },
                component: () => import('@/views/return-order/ReturnOrderList.vue')
            },
            {
                path: 'detail',
                name: 'ReturnOrderDetail',
                meta: { title: '订单详情' },
                component: () => import('@/views/return-order/ReturnOrderDetail.vue')
            },
            {
                path: 'add',
                name: 'AddSalesOrder',
                meta: { title: '添加订单' },
                component: () => import('@/views/return-order/AddReturnOrder.vue')
            },
        ]
    },
    {
        path: '/data',
        component: LayoutView,
        children: [
            {
                path: 'ranking',
                name: 'RankingView',
                meta: { title: '排行榜' },
                component: () => import('@/views/data/RankingView.vue')
            },
            {
                path: 'clueData',
                name: 'ClueData',
                meta: { title: '线索数据' },
                component: () => import('@/views/data/ClueData.vue')
            },
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

document.title = '龙侨华PRM系统'; // 设置默认标题

export default router
