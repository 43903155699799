import router from '@/router'
import { getAdminInfo } from '@/api/admin'
import { getToken, setToken, removeToken } from '@/utils/cookies'

export default {
	state: {
		name: '',
		role: '',
		token: getToken() || '',
	},
	mutations: {
		SET_NAME(state, name) {
			state.name = name
		},
		SET_ROLE(state, role) {
			state.role = role
		},
		SET_TOKEN(state, token) {
			state.token = token
			setToken(token);
		},
	},
	actions: {
		setName({ commit }, name) {
			commit('SET_NAME', name)
		},
		setRole({ commit }, role) {
			commit('SET_ROLE', role)
		},
		setToken({ commit }, token) {
			commit('SET_TOKEN', token)
		},
		getAdminInfo({ commit }) {
			return new Promise((resolve) => {
				getAdminInfo().then((res) => {
					commit('SET_NAME', res.data.username)
					commit('SET_ROLE', res.data.role_id)
					resolve(res)
				})
			})
		},
		logout({ commit }) {
			removeToken();
			commit('SET_ROLE', '');
			commit('SET_TOKEN', '');
			router.push("/login");
		}
	}
}